import React, { useState } from 'react'
import logo from '../assets/logo.png';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


function Login(props) {

    const [number, setNumber] = useState(null);

    const [error, setError] = useState({});

    const navigate = useNavigate();

    const submitHanlder = (e) => {
        e.preventDefault();
        if (error.valid) {
            // send otp to the customer
            
            axios
                .get("https://production.invoiz.ae/send-otp-client?number=" + number)
                
                .then(response => {
                    localStorage.setItem('token',response.data.token);
                    localStorage.setItem('number',number);
                    navigate('/otp');
                })
                .catch(err => {
                    setError({ msg: err.message, valid: false });
                })
        }

    }

    return (

        <div className="container">
            <div className="header-logoo mb-30">
                <img src={logo} />
                <h5 className="mt-20 mb-20">Login to View your previous receipts</h5>
            </div>

            <div className="receipt-subheading">Enter mobile number to receive OTP</div>
            <form>
            <div className="form-group">
                <IntlTelInput
                    format={false}
                    containerClassName="intl-tel-input"
                    inputClassName="form-control"
                    fieldName='name'
                    preferredCountries={['in']}
                    onPhoneNumberChange={(status, value, countryData, number, id) => {
                        setError({ msg: "Invalid number", valid: status });
                        if (status || !value) {
                            setNumber(value);
                            setError({msg:"",valid:status});
                        }

                    }}
                />
                <span className='error'>{error.msg}</span>
            </div>

            <div className="terms-conditions">
                <p>By submitting your mobile number, you accept the <a href="#" style={{ color: '#8dba1b' }}>Terms &amp; Conditions</a> and <a href="#" style={{ color: '#8dba1b' }}>Privacy Policies</a></p>

            </div>

            <button onClick={submitHanlder}>Submit</button>
            </form>

        </div>

    )
}

export default Login