import React, { useEffect, useState } from 'react'
import logo from '../assets/logo.png'
import { useLocation, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import axios from 'axios';

function Otp(props) {

    const [otp, setOtp] = useState('');
    const [ error, setError ] = useState('');
    const navigate = useNavigate();

    const onSubmitHandler = (e) => {
        e.preventDefault();
        
        let token  = localStorage.getItem('token');

        setError({ msg: "", valid: true });

        axios
            .get("https://production.invoiz.ae/verify-otp-client?token=" + token + "&otp=" + otp)

            .then(response => {
                
                navigate('/showreceipt');
            })
            .catch(err => {
             
                setError({ msg: err.response.data.message, valid: false });
            })
    }

    const handleResend = (() => {

        console.log('handler resent to mobile number here');


        let number  = localStorage.getItem('number');

        setError({ msg: "", valid: true });

        axios
        .get("https://production.invoiz.ae/send-otp-client?number=" + number)
        
        .then(response => {

            localStorage.setItem('token',response.data.token);

        })
        .catch(err => {
            setError({ msg: err.response.data.message, valid: false });
        })

    });

    return (

        <div className="container">
            <div className="header-logoo mb-30">
                <img src={logo} />

                <h5 className="mt-20 mb-20">Login to View your previous receipts</h5>
            </div>
            <form onSubmit={onSubmitHandler} noValidate={true}>
                <div className="otp-sec">

                    <div className="receipt-subheading">Enter 6 Digit OTP</div>

                    <div className="input-field">
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                            inputStyle="inputStyle"
                            inputType={"number"}
                        />
                         
                    </div>
                    <div className="resend-otp">
                        <small><a href="#" className='btn btn-sm resend-button' onClick={handleResend}>Resend for OTP</a></small>
                    </div>
                    <span className='error'>{error.msg}</span>
                </div>

                <div className="terms-conditions">
                    <p>By submitting your mobile number, you accept the <a href="#" style={{ color: '#8dba1b' }}>Terms &amp; Conditions</a> and <a href="#" style={{ color: '#8dba1b' }}>Privacy Policies</a></p>
                </div>

                <button>Submit</button>
            </form>

        </div>

    )
}

export default Otp