import React, { useEffect } from 'react'
import logo from '../assets/logo.png';
import DatePicker from "react-datepicker";
import { useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import refersh from '../assets/refresh.png';
import eye from '../assets/eye.png';
import share from '../assets/sahre.jpg';
import download from '../assets/down.jpg';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';
import { saveAs } from 'file-saver'
import fileDownload from 'js-file-download'
import moment from 'moment';

import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    WhatsappIcon
  } from "react-share";


function Receipt() {

    const [index,setIndex] = useState("");
    const [start,setStart] = useState("");
    const [end,setEnd] = useState("");
    const [isOpen,setIsOpen] = useState(false);
    const [image,setImage] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [receipts, setReceipts] = useState([]);
    const { error, setError } = useState('');
    const [loading, setLoading] = useState(false);


    useEffect(() => {

        async function getData() {

            let token = localStorage.getItem('token');

            setLoading(true);

            const response = await axios("https://production.invoiz.ae/show-receipts?token=" + token+"&start="+start+"&end="+end);

            setReceipts(response.data);

            setLoading(false);

            if(response.data.length) {
                response.data.forEach(element => {

                    setImage(image => [...image,element.s3_url]);
                });
            }

        }

        getData();
        
    }

        , []);


        const filterRecords = (async () => {


            let token = localStorage.getItem('token');

            setLoading(true);

            const response = await axios("https://production.invoiz.ae/show-receipts?token=" + token+"&start="+start+"&end="+end);

            setReceipts(response.data);

            setLoading(false);

            if(response.data.length) {
                response.data.forEach(element => {

                    setImage(image => [...image,element.s3_url]);
                });
            }

        }


        );


    // useEffect(() => {
    //     console.log(image)
    // }, [image])


    const resentRequest = (async () => {

        let token = localStorage.getItem('token');

        setEndDate(null);

        setStartDate(null);

        setLoading(true);

        const response = await axios("https://production.invoiz.ae/show-receipts?token=" + token);

        setReceipts(response.data);

        setLoading(false);

        if(response.data.length) {
            response.data.forEach(element => {

                setImage(image => [...image,element.s3_url]);
            });
        }

    })

    const downloadImage = (url) => {
        saveAs(url, "Receipt.png")
        // axios.get(url, {
        //   responseType: 'blob',
        //   header:{'cross-origin':'*'}
        // })
        // .then((res) => {
          
        // })
      }

    return (
        <div>

            <div className="container-previous-receipt">
                <div className="header-logoo mb-20 mt-80">
                    <img src={logo} />

                    <h5 className="mt-20 mb-20">Previous receipts</h5>

                    <div id="date-picker-section">
                        <div id="date-picker-container">

                            <div className='date-picker-dates'>

                                <label>From:</label>
                                <div className='date-picker-date date-picker-date-from'>
                                    <DatePicker
                                        selected={startDate}
                                        // value={startDate}
                                        isClearable={true}
                                        onSelect={(date) => {

                                            setStart(moment(date).format('YYYY-MM-DD'));
                                         
                                             setStartDate(date);

                                            } 
                                    } //when day is clicked
                                        // onChange={(date) => setStartDate(moment(date).format('YYYY-MM-DD'))} //only when value has changed
                                        dateFormat={'dd-M-yyyy'}
                                    />
                                </div>

                                <label className='to-label'>To:</label>
                                <div className='date-picker-date date-picker-date-from'>
                                    <DatePicker
                                        // value={endDate}
                                        selected={endDate}
                                        isClearable={true}
                                        onSelect={(date) => {

                                            setEnd(moment(date).format('YYYY-MM-DD'));
                                         
                                            setEndDate(date);

                                            filterRecords();
                                        } } //when day is clicked
                                        // onChange={(date) => setStartDate(moment(date).format('YYYY-MM-DD'))} //only when value has changed
                                        dateFormat={'dd-M-yyyy'}
                                    />
                                </div>

                                <span className="refresh-btn"><img src={refersh} onClick={resentRequest} /></span>

                            </div>


                            {/* <div id="date-picker-modal" className="hidden-2">
                                    <div id="date-picker-top-bar">
                                        <div id="date-picker-previous-month" className="date-picker-change-month">‹</div>
                                        <div id="date-picker-month">September 2023</div>
                                        <div id="date-picker-next-month" className="date-picker-change-month">›</div>
                                    </div>
                                    <div id="date-picker-exit">×</div>
                                    <table id="date-picker">
                                        <tr id="date-picker-weekdays">
                                            <th>S</th>
                                            <th>M</th>
                                            <th>T</th>
                                            <th>W</th>
                                            <th>T</th>
                                            <th>F</th>
                                            <th>S</th>
                                        </tr><tr className="date-picker-calendar-row"><td className="previous-month">27</td><td className="previous-month">28</td><td className="previous-month">29</td><td className="previous-month">30</td><td className="previous-month">31</td><td>1</td><td>2</td></tr><tr className="date-picker-calendar-row"><td>3</td><td>4</td><td>5</td><td>6</td><td>7</td><td>8</td><td>9</td></tr><tr className="date-picker-calendar-row"><td>10</td><td>11</td><td>12</td><td>13</td><td>14</td><td>15</td><td>16</td></tr><tr className="date-picker-calendar-row"><td>17</td><td>18</td><td className="active">19</td><td>20</td><td>21</td><td>22</td><td>23</td></tr><tr className="date-picker-calendar-row"><td>24</td><td>25</td><td>26</td><td>27</td><td>28</td><td>29</td><td>30</td></tr></table>
                                </div> */}

                        </div>
                    </div>

                    {/* <div className="search-secs">

                        <div className="filter-cat row">
                            <div className="sales-products-filter">
                                <select className="filter-brand classNameic">
                                    <option value="0" selected="">Filter</option>
                                    <option value="Date">All</option>
                                    <option value="Brand 1">Brand 1</option>
                                    <option value="Brand 2">Brand 2</option>

                                </select>
                            </div>
                        </div>

                    </div> */}

                </div>
                <div className="search-secs">
                    {loading ? "Loading Please wait....." :
                    <table className="prevoius-receipts">
                        <thead>

                        </thead>
                        <tbody>

                            {receipts.map((item,index) => {

                                return (

                                <tr key={item.id}>

                                    <td>
                                        <div className="js-left">
                                            <img src={item.profile_image} />
                                        </div>
                                        <div className="js-right">
                                            <span className="view-brand-name">{item.company_name}</span>
                                            <span className="view-brand-name-small">{item.name} ({item.branch_uid})</span>
                                            <span className="view-brand-date-small">{item.created_at} <span>
                                            </span></span></div>
                                    </td>
                                    <td className="icon-section-feedback">
                                        <a><img className="feedback-view-download" onClick={() => {
                                            setIndex(index);
                                            setIsOpen(true);
                                        }} src={eye} alt="view" />
                                      
                                        </a>
                                        <a><WhatsappShareButton url={item.s3_url}><WhatsappIcon className='whatsapp-icon-class' size={20} round={true}/></WhatsappShareButton></a>
                                        {/* <img className="feedback-view-download" src={share} alt="Share" /></a> */}
                                        <a onClick={() => downloadImage(item.s3_url)} download={true}><img className="feedback-view-download" src={download}  alt="Download" /></a>

                                    </td>

                                </tr>

                                );


                            })}


                        </tbody>

                        {isOpen && (
                            <Lightbox
                                mainSrc={image[index]}
                                nextSrc={image[(index + 1) % image.length]}
                                prevSrc={image[(index + image.length - 1) % image.length]}
                                onCloseRequest={() =>  setIsOpen(false) }
                                onMovePrevRequest={
                                    () =>
                               
                                        setIndex((index + image.length - 1) % image.length)
                                
                                }
                                onMoveNextRequest={() =>
                               
                                setIndex((index + 1) % image.length)
                               
                                }
                            />
                            )}

                    </table>
                    }
                </div>
            </div>


        </div>
    )
}

export default Receipt