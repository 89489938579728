import logo from './logo.svg';
import './App.css';
import LOGIN from './components/login';
import FOOTER from './components/footer';
import RECEIPT from './components/receipts';
import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import Otp from './components/Otp';



function App() {
  return (
   <>
   <Routes>
    <Route path="/" element={<LOGIN/>}  />
    <Route path="/otp" element={<Otp/>}  />
    <Route path='/showreceipt' element={<RECEIPT/>}/>
   </Routes>
   <FOOTER/>
   </>
  );
}

export default App;
